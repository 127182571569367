/**
 * Post Short List
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader } from '@fortawesome/free-solid-svg-icons';

const postShortList = ({ data }) => {
  return (
    <article className="pt-4">
      {data.map(field => (
        <section className="container" key={field.fieldValue}>
          <h4 className="py-3 font-weight-bold text text-capitalize text-muted">
            In
            {field.fieldValue}
          </h4>
          <div className="py-2">
            {field.posts.map(post => (
              <Link to={post.slug} key={post.slug} className="border-bottom list-group-flush list-group-item-action">
                <div className="row p-0 h-100 align-content-center justify-content-start no-gutters">
                  <div className="col-md-4 p-0 align-self-center">
                    <Img
                      fluid={post.cover.childImageSharp.fluid}
                      loading="eager"
                      className="m-0 w-100"
                      alt={post.slug}
                    />
                  </div>
                  <div className="col-md-8 bg-neutralLighterAlt">
                    <div className="row align-content-center m-0 h-100">
                      <div className="card-body col align-self-center">
                        <h5 className="card-title py-2 m-0 text-dark">{post.title}</h5>
                        <div className="col p-0 justify-content-end align-self-center">
                          <FontAwesomeIcon className="text-primary" icon={faBookReader} />
                          <small className="text-right text-muted p-2 ">
                            {post.timeToRead}
                            &ensp;&mdash;&ensp;min
                          </small>
                        </div>
                        <p className="card-text small text-muted">{post.date}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </section>
      ))}
    </article>
  );
};

postShortList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fieldValue: PropTypes.string.isRequired,
      posts: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          timeToRead: PropTypes.number.isRequired,
          slug: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          cover: PropTypes.object,
        }),
      ),
    }).isRequired,
  ).isRequired,
};

export default React.memo(postShortList);
